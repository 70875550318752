// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/providers/staff/provider.ts"
);
import.meta.hot.lastModified = "1733087018000";
}
// REMIX HMR END

import { QueryOptions, sdk } from '~admin/graphqlWrapper'

import {
  RsvStaffListOptions,
  Rsv_AddStaffMutation,
  Rsv_StaffFormQuery,
  Rsv_StaffQuery,
  Rsv_RemoveStaffMutation,
  Rsv_UpdateStaffMutation,
  Rsv_SearchStaffQuery,
  Rsv_StaffListQuery,
  RsvCreateStaffInput,
  RsvUpdateStaffInput,
  Rsv_StaffByUserIdQuery,
} from '~admin/generated/graphql'

export const search = (
  search?: string,
  queryOptions?: QueryOptions,
): Promise<Rsv_SearchStaffQuery> => {
  return sdk.rsv_searchStaff({ search }, queryOptions)
}

export const getStaffByUserId = (
  userId: string,
  options?: QueryOptions,
): Promise<Rsv_StaffByUserIdQuery> => {
  return sdk.rsv_staffByUserId({ userId }, options)
}

export const get = (
  id: string,
  options?: QueryOptions,
): Promise<Rsv_StaffQuery> => {
  return sdk.rsv_staff({ id }, options)
}

export const getForm = (
  id: string,
  options?: QueryOptions,
): Promise<Rsv_StaffFormQuery> => {
  return sdk.rsv_staffForm({ id }, options)
}

export const list = (
  options?: RsvStaffListOptions,
  queryOptions?: QueryOptions,
): Promise<Rsv_StaffListQuery> => {
  return sdk.rsv_staffList({ options }, queryOptions)
}

export const add = (
  input: RsvCreateStaffInput,
  options?: QueryOptions,
): Promise<Rsv_AddStaffMutation> => {
  return sdk.rsv_addStaff({ input }, options)
}

export const update = (
  id: string,
  input: RsvUpdateStaffInput,
  options?: QueryOptions,
): Promise<Rsv_UpdateStaffMutation> => {
  return sdk.rsv_updateStaff({ id, input }, options)
}

export const remove = (
  id: string,
  options?: QueryOptions,
): Promise<Rsv_RemoveStaffMutation> => {
  return sdk.rsv_removeStaff({ id }, options)
}
